//header.html, footer.html読み込み
export default function PARTSLOADER(config) {

  const w = window;
  $(()=>{
    $.each(config,function(i,option){
      let opt = $.extend(true,{
        loadhtml: false,
        target: 'body',
        method: 'append',
        callback(res,opt){ if(opt.debug) console.log('%c%s%c is loaded','font-weight: bold',res.url,'')},
        name: '',
        debug: false
      },option)
      var mes = 'loadhtml error: '
      $.get(opt.loadhtml)
        .done(function(html){
          if($(opt.target)[opt.method](html).length != 0){ opt.callback(this,opt) }
          else{
            mes+='Not found DOM $(\'%s\').\nCouldn\'t load \'%s\'';
            if(opt.debug) console.error(mes,opt.target,this.url);
          }
          w.load[opt.name].resolve(html)
        }).fail(function(data){ //ajaxの通信に失敗した場合
          mes+='Ajax failed \'%s\'\n%s (%s)';
          if(opt.debug) console.error(mes,this.url,data.statusText,data.status);
          w.load[opt.name].reject(data)
          return false;
        });
    })
  })

}