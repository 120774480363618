import VIEWPORTS from '../plugins/viewports'
import PARTSLOADER from '../plugins/partsloader'
import CONTENT_TITLE from '../plugins/contentTitle'

// スクロールして画面エリアに入ったら要素を表示する lazydisp
import LAZYDISP from '../plugins/lazydisp'

// IEでのpictureタグ対応
import IE_SRCSET from '../plugins/polyfill-ie-srcset'


// IE11でSVGの外部参照を有効にする
import 'svgxuse'

export default function INIT() {

  IE_SRCSET();

  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'footer': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'mapScript': new $.Deferred, // googlemapAPIのURLパラメータでresolveします
    'loadHtml': new $.Deferred, // すべてのLoad Htmlが完了したらresolveする
  }

  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer',
      method:'replaceWith',
      callback(){
        $('footer [data-plugin="getCopyright"]').getCopyright()
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        $('header .p-gnav').currentMenu({mode:1,default:999});
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':`<span>サイトマップ</span><small>SITEMAP</small>`,
    'privacy':`<span>プライバシーポリシー</span><small>PRIVACY POLICY</small>`,
    'profile':`<span>会社概要</span><small>PROFILE</small>`
  })

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
    // ページトップリンクをスクロール量で表示するサンプル
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-pagetop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-pagetop__wrap').removeClass('hidden');
      }
    })

    // lazydisp
    $.when( window.load['loadHtml'] ).then( () => LAZYDISP('[data-lazydisp]') )
  })

}