const [ w, d, UA ] = [ window, document, window.navigator.userAgent.toLowerCase() ]
const init = ( promise, imgs = d.querySelectorAll('img[data-ie-width]') ) => {
  Array.prototype.forEach.call( imgs, add_width )
}
const add_width = img => {
  img.width = img.dataset.ieWidth
  img.removeAttribute('data-ie-width')
}
export default function IE_SRCSET () {
  if( UA.indexOf('msie') != -1 || UA.indexOf('trident') != -1 ){ // IEの時
    d.addEventListener('DOMContentLoaded', () => {
      init()
      w.load['loadHtml'].then( init )
    })
  }
}