((w,d) => {
  
  // query_stringを定義
  w.query_string = (param) => {
    if(!location.search) return false
    let params = location.search.substr(1).split('&')
    params.map( v => { const p=v.split('=');params[p[0]]=p[1]})
    return params[param]
  }

  w.addScript = (attrs) => {
    const [d,s] = [document,'script']
    const f=d.getElementsByTagName(s)[0],j=d.createElement(s)
    Object.keys(attrs).forEach(a=>j.setAttribute(a,attrs[a]))
    f.parentNode.insertBefore(j,f)
  }

  w.addStylesheet = (attrs) => {
    const [d,s] = [document,'link']
    const f=d.getElementsByTagName(s)[0],j=d.createElement(s)
    j.rel="stylesheet"
    Object.keys(attrs).forEach(a=>j.setAttribute(a,attrs[a]))
    f.parentNode.appendChild(j)
  }

  w.datetostr = (unixtime,format) => {
    const date = new Date( unixtime )
    const month = ["january","february","march","april","may","june","july","august","september","october","november","december"]
    const weekday = ["日", "月", "火", "水", "木", "金", "土"]
    const weekday_en = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    format = format
      .replace(/YYYY/g, date.getFullYear())
      .replace(/MMMM/g, month[date.getMonth()])
      .replace(/MMM/g, month[date.getMonth()].slice(3))
      .replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
      .replace(/DD/g, ('0' + date.getDate()).slice(-2))
      .replace(/D/g, date.getDate())
      .replace(/dddd/g, weekday_en[date.getDay()])
      .replace(/ddd/g, weekday_en[date.getDay()].slice(3))
      .replace(/dd/g, weekday[date.getDay()])
      .replace(/hh/g, ('0' + date.getHours()).slice(-2))
      .replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
      .replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
    return format
  }
  
  $(() => {

    // ポップアップウインドウ
    $(d).on('click','[data-plugin=openwindow]',function(e){
      e.preventDefault();
      var $this = $(this),
          data = $this.data(),
          size = 'width='+(data.width||600)+', height='+(data.height||500)+',';
      w.open(this.href,(data.title||'share this site'),size+"menubar=no, toolbar=no, scrollbars=yes");
    });

    // スマホフッターカスタム
    (function(_){ if(!$.UA.is_mobile && _.$footer.length == 0) return;
      _.$footer.parent().insertAfter( _.$needle );
    }({
      $footer: $('.detailFooter .pageingBtnBlock'),
      $needle: ( $('.listContents').length )? $('.listContents') : $('.element_detail').parent()
    }));

    // FacebookSDK
    // $.ajaxSetup({ cache: false });
    // $.getScript('//connect.facebook.net/ja_JP/sdk.js', function(){
    //   FB.init({
    //     appId: '759600244139396',
    //     xfbml: true,
    //     version: 'v2.8'
    //   });
    // });

  })

  $(w).on('load',()=>{

    // table.responsiveを外包するwrapperを作る(readyfunctionに移す)
    if( is_mobile() ){  // eslint-disable-line no-undef
      (function(_){
        _.$table.each(function(){
          $(this).wrapAll($('<div />').addClass(_.wrapperClass));
        });
      }({
        $table:$('table.responsive'),
        wrapperClass:'tableResponsiveWrappr'
      }));
    }

    // アンカーリンクスクロール設定
    $(d).on('click','a[href^="#"],a[href*="#"]',function(e){
      var path = $(this).attr('href').match(/^([^#]+)(#.*)?/)||[];
      var mq = w.matchMedia( "(max-width: 900px)" )
      if( path[1] === location.pathname + location.search ){
        e.preventDefault();
        $(this).attr('href',path[2]);
      }else if( path[1] != undefined ){
        return true
      }
      var speed =   500,
          href =    $(this).attr('href'),
          $target = $(href == '#pagetop' || href == '#top' ? 'html' : href ),
          pos =     $target.offset().top;
          if( mq.matches ){
            var headerHeight = $('.p-header').height();
            pos = (pos - headerHeight);
          }
      $('body,html').animate({scrollTop:pos},speed,'easeInOutExpo');
      if(href!='#pagetop' && href!='#top')
        window.history.pushState(null, null, this.hash);
      return false;
    });

    // ハッシュURLでスクロール
    (function(_){
      const scroll_screen = () => {
        if(_.hash != '' && $(_.hash).length ){
          var pos = $(_.hash).offset().top;
          if( _.mq.matches ){
            var headerHeight = $('header#header').height();
            pos = (pos - parseInt( headerHeight ) );
          }
          _.$target.animate({'scrollTop':pos},200,'easeInOutExpo');
        }
      }
      scroll_screen()
      $(() => w.load['loadHtml'].then( scroll_screen ) )
    }({
      hash:location.hash,
      $target:$('body,html'),
      mq : w.matchMedia( "(max-width: 900px)" )
    }));

  })

})(window,document);