import GET_ARTICLES from '../plugins/getArticles';

const [ w, d, mq ] = [ window, document, window.matchMedia( "(max-width: 900px)" ) ];

const jqInit = () => {

  // works
  $.when(w.load['block-works']).then( () => {
    let makeItem = item => $(`
      <li class="works-item">
        <a href="${item.href}" class="works-item__link">
          <figure class="works-item__img">
            <div class="works-item__img-cover" style="background-image:url(${item.thumb})"><img src="${item.thumb}" alt="${item.title}" width="200" loading="lazy"></div>
          </figure>
          <div class="works-item__body">
            <span class="cate">${item.category.name}</span>
            <span class="date">${item.date}</span>
            <span class="subject ${item.is_new ? 'is-new': ''}">${item.title.str_truncate(24)}</span>
            <span class="desc">${item.desc.str_truncate(24)}</span>
          </div>
        </a>
      </li>
    `)[0]
    GET_ARTICLES('.block-works__list[data-plugin="getArticles"]', { makeItem })
  });

  // topics
  $.when( w.load['block-news'] ).then( () => {
    let makeItem = item => $(`
      <a class="block-news-item" href="${item.href}">
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
        <span class="subject ${item.is_new ? 'is-new': ''}">${item.title}</span>
      </a>
    `)[0]
    GET_ARTICLES('.block-news__list[data-plugin="getArticles"]', { makeItem });
  });

  // SVGリンクのエフェクト
  $.when( w.load['block-concept'] ).then( () => {
    const $svgs = $('svg.bean-btn', '.block-concept');
    $svgs.each((i,v) => {
      let hoverObj = v.querySelector('circle.front-hover');
      v.addEventListener('mouseenter', e => {
        hoverObj.setAttribute( 'cx', e.offsetX );
        hoverObj.setAttribute( 'cy', e.offsetY );
        hoverObj.setAttribute( 'r', '375' );
      });
      v.addEventListener('mouseleave', e => {
        hoverObj.setAttribute( 'r', '0' );
      })
    })
  });

}

export default function HOME() {

  $( jqInit )

}