/************************
 * 記事取得、整形モジュール
 * 
 * usage:
 * <!-- コンテナ要素を作成 -->
 * <div data-plugin="getArticles"><p>Loading...</p></div>
 * 
 * ※オプションはコンテナのdata属性にて上書き可能
 * 
 * 必須オプション
 * [content]  :: コンテンツ名が入ります。(ex "news")
 * 
 * 使用できるオプション
 * [amount]   :: 表示件数制限 '-1'ですべて取得 デフォルトは'-1'
 * [status]   :: 記事のステータスでフィルタする。デフォルトは'publish'(all|publish|hidden)
 * [type]     :: 表示タイプ(link|card|media) ※カスタムの場合は値なしもしくは'own'
 * [makeItem(item,opt)] :: (Function) 表示タイプのカスタマイズ。1記事のDOMObjectを返す
 * [newDuration] :: NEWマークの表示期間設定。デフォルトは14
 * [prefix]   :: BEMのプリフィクスを設定(`p-block-${container.dataset.content}__`)
 * [cat]      :: カテゴリでフィルタする(カンマ区切りで複数指定、マイナス符号だと除外)
 * [articles] :: 取得する記事のID(カンマ区切りで複数)
 * [sort]     :: [DESC|ASC|RAND]
 * [dateFormat] :: 日付フォーマット変更(YYYY.MM.DD)
 * [exclude]    :: 除外する記事
*/

// タイプ別に出力HTMLを変える
const make_item = (type,item,opt) => {
  switch(type){
    case 'link':
      return $(`<a href="${item.href}" class="${opt.prefix}item">${item.title}</a>`)[0]
    case 'cat+title':
      return $(`
        <p class="${opt.prefix}item">
          <a href="${item.category.href}">［${item.category.name}］</a>
          <a href="${item.href}">${item.title}</a>
        </p>
      `)[0]
    case 'card':
    case 'media': {
      const $link = $(`
        <a href="${item.href}" class="${opt.prefix}${type}">
          <div class="${opt.prefix}${type}-img">
            <div class="${opt.prefix}${type}-img__inner" style="background-image:url('${item.thumb}')"><img src="${item.thumb}" alt="${item.title}" /></div>
          </div>
          <div class="${opt.prefix}${type}-body">
            <h4 class="${opt.prefix}${type}-title">${item.title}</h4>
            <p class="${opt.prefix}${type}-date">${item.date}</p>
          </div>
        </a>
      `)
      const $catlink = $(`<a href="${item.category.href}" class="${opt.prefix}${type}-category">${item.category.name}</a>`)
      $link.find(`.${opt.prefix}${type}-body`).prepend( $catlink )
      return $link[0]
    }
  }
}

// 日付表示を指定したフォーマットで変換する
const datetostr = (date,format) => {
  const month = ["january","february","march","april","may","june","july","august","september","october","november","december"]
  const weekday = ["日", "月", "火", "水", "木", "金", "土"]
  const weekday_en = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
  format = format
    .replace(/YYYY/g, date.getFullYear())
    .replace(/MMMM/g, month[date.getMonth()])
    .replace(/MMM/g, month[date.getMonth()].slice(3))
    .replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
    .replace(/DD/g, ('0' + date.getDate()).slice(-2))
    .replace(/D/g, date.getDate())
    .replace(/dddd/g, weekday_en[date.getDay()])
    .replace(/ddd/g, weekday_en[date.getDay()].slice(3))
    .replace(/dd/g, weekday[date.getDay()])
    .replace(/hh/g, ('0' + date.getHours()).slice(-2))
    .replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
    .replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  return format
}

export default function GET_ARTICLES(area='body',option={}) {

  let $containers = $('[data-plugin="getArticles"][data-content]',area)

  if( $containers.length == 0 && $(area).data('plugin')=='getArticles' && $(area).data('content')!='' ){
    $containers = $(area)
  }else if($containers.length == 0){
    return false
  }
  
  // Newマークを表示する判定
  const is_new = (date,d) => ( new Date() - (1000*60*60*24*d) <= date )? true : false
  const defarr = [] // containerごとのpromise格納
  $containers.each( (i,container) => {
    const d = new $.Deferred()
    defarr.push(d.promise())
    $.when(
      $.ajax({
        url: '/asset/api/getCategories/',
        dataType: 'json',
        data: {content: container.dataset.content},
        cache: false
      }),
      $.ajax({
        url: '/asset/api/getArticles/',
        dataType: 'json',
        data: {
          content: container.dataset.content,
          post_per_page: option.amount||container.dataset.amount||-1,
          post_status: option.status||container.dataset.status||'publish',
          category_in: option.cat||container.dataset.cat||'',
          post_id: option.articles||container.dataset.articles||'',
          sort: option.sort||container.dataset.sort||'DESC',
          exclude: option.exclude||container.dataset.exclude||'',
        },
        cache: false
      })
    ).done((cats,items) => {
      const opt = $.extend(true,{
                    type:'link',
                    makeItem: null,
                    dateFormat:'YYYY.MM.DD',
                    newDuration : 14,
                    prefix: `p-block-${container.dataset.content}__`,
                  },option,container.dataset) // 設定の上書き
      // カテゴリデータ整形
      cats = cats[0]
      
      // 記事データ整形
      items = items[0].map(item => {
        // カテゴリIDをオブジェクトに変換
        item.category = cats.filter(cat => cat.id == item.category).shift()
        if(!item.category) item.category = {id:'0',name:'その他',href:`/${container.dataset.content}/?mode=list&cat=0`}
        // NEWマーク判定
        item.is_new = is_new(item.date*1000,opt.newDuration)
        // 日付表記変換
        const d = new Date(item.date*1000)
        item.date = datetostr(d,opt.dateFormat)
        return item
      })
      
      container.innerHTML = '' // 要素を空にしてリセット

      $.each(items ,(i,item) => { 
        if(item.title == '') return false
        container.appendChild(
          (opt.type=="own" || opt.makeItem != null)? opt.makeItem(item,opt) : make_item(opt.type,item,opt)
        )
      })
      d.resolve(items)
    })
  })

  return $.when.apply($,defarr)
}