import 'intersection-observer' //polyfill

const [d] = [document]

export default function LAZY_DISP (selector){

  const options = {
    root: null, // ビューポートをルート要素とする
    rootMargin: "20% 0px -20%", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  }
  const callback = (entries, object) => {
    entries.forEach( entry => {
      if (entry.isIntersecting) {
        entry.target.classList.remove('lazyhide')
        if ( entry.target.dataset.lazyAddclass != undefined ){
          let classname = entry.target.dataset.lazyAddclass
          entry.target.classList.add( classname )
        }
        object.unobserve(entry.target)
      }
    })
  }
  const ovserver = new IntersectionObserver(callback,options)

  const init = () => {
    const targets = d.querySelectorAll(selector)
    Array.prototype.forEach.call(targets, target => {
      target.classList.add('lazyhide')
      target.classList.add('lazyReady')
      ovserver.observe(target)
      setTimeout(() => target.classList.remove('lazyReady'), 500 )
    })
  }

  init()
  d.addEventListener('DOMContentLoaded', init )
}