/*****
 * URLによる読み込み分岐
 */
export default function LOADER (loaderSwitch) {
  // 条件分岐のためのURL正規化
  let [ w, d, l] = [ window, document, location ]
  let md, key, id
  md = w.query_string('mode')
  id = w.query_string('article')
  if( md == 'detail' ) key = 'article'
  else if( md == 'list' && id ) key = 'cat'
  let path = l.pathname.replace('main.php','')
  let request = ( md && key && id )? path + `?mode=${md}&${key}=${id}`: path
  loaderSwitch( request, path )
}